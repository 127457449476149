<div class="p-5">
  <div class="header d-flex justify-content-between">
    <span class="ubuntu-bold font-size-24">{{'PLANNING.CARDS.FOOTER.PUNCH_IN_OUT' | translate}}</span>
    <div class="icon-close icon-16 pointer" (click)="activeModal.dismiss('Cross click')"></div>
  </div>
  <div class="body my-5 punch-in-out-conditions-content">
    <ng-container *ngIf="savedPunchInOuts$ | async as savedPunchInOuts">
      <div class="row" *ngFor="let savedPunch of savedPunchInOuts; let punchIndex = index">
        <div class="saved-punch-dot col-1 d-flex align-items-center justify-content-end">
            <div class="dot position-relative"
            [ngClass]="{
              'dot-start': punchIndex === 0 && savedPunchInOuts.length > 1,
              'dot-between': punchIndex > 0 && punchIndex < (savedPunchInOuts.length - 1),
              'dot-end':  punchIndex === (savedPunchInOuts.length - 1) && savedPunchInOuts.length > 1
            }"
            ></div>
        </div>
        <div class="col-11 d-flex align-items-center justify-content-start">
          <div class="mb-0 ubuntu-regular font-size-16 d-flex align-items-center">
            <ng-container *ngIf="savedPunch.punchTime">
              {{ savedPunch.punchTime | hoursMinutesConverter }}
            </ng-container>
            <div class="font-size-10 mx-2 ubuntu-bold saved-punch-day-badge text-charcoal-grey bg-gallery d-flex justify-content-center align-items-center"
            *ngIf="savedPunch.dayAfter || savedPunch.dayBefore">
              <div *ngIf="savedPunch.dayAfter">
                {{'PLANNING.CARDS.CONTENT.DAY' | translate}}+1
              </div>
              <div *ngIf="savedPunch.dayBefore">
                {{'PLANNING.CARDS.CONTENT.DAY' | translate}}-1
              </div>
            </div>
            <ng-container *ngIf="savedPunch.type">
              {{ savedPunch.type | uppercase }}
            </ng-container>
            <ng-container *ngIf="savedPunch.departmentCode">
              {{ '- ' + savedPunch.departmentCode }}
            </ng-container>
            <ng-container *ngIf="savedPunch.departmentLabel">
              {{ '- ' + savedPunch.departmentLabel}}
            </ng-container>
          </div>
        </div>
      </div>
      <form [ngClass]="savedPunchInOuts.length ? 'mt-5' : ''" [formGroup]="punchInOutConditionsForm">
        <p class="ubuntu-bold font-size-16">{{'PUNCH_IN_OUT.CONDITIONS.WISH_TITLE' | translate}}</p>
        <label class="d-flex ps-5 mb-2 pointer my-2" for="IN">
          <div class="position-relative me-5 mt-2">
            <input checked class="form-input-radio" type="radio" name="type" value="IN" id="IN" 
            formControlName="type">
            <div class="radio-check-container d-flex justify-content-center align-items-center">
              <div class="icon icon-16 icon-checked-white"></div>
            </div>
          </div>
          <p class="d-flex flex-column my-2 ubuntu-regular font-size-16">
            {{'PUNCH_IN_OUT.IN' | translate | uppercase}}
          </p>
        </label>
        <label class="d-flex ps-5 mb-2 pointer my-2" for="OUT">
          <div class="position-relative me-5 mt-2">
            <input class="form-input-radio" type="radio" name="type" value="OUT" id="OUT" 
            formControlName="type">
            <div class="radio-check-container d-flex justify-content-center align-items-center">
              <div class="icon icon-16 icon-checked-white"></div>
            </div>
          </div>
          <p class="d-flex flex-column my-2 ubuntu-regular font-size-16">
            {{'PUNCH_IN_OUT.OUT' | translate | uppercase}}
          </p>
        </label>

        <!-- IF CHECK OUT-->
        <ng-container *ngIf="punchInOutConditionsForm.controls.type.value === 'OUT'">
          <p class="ubuntu-bold font-size-16 mt-5">{{'PUNCH_IN_OUT.CONDITIONS.WORKDEPARTMENTS' | translate}} </p>
          <div class="w-100 h-100" formArrayName="workOutsideDepartments">
            <div class="row mt-3" *ngFor="let workOutsideDepartment of punchInOutConditionsForm.get('workOutsideDepartments')['controls']; let i = index"
            [formGroupName]="i">
            <label class="col-12 d-flex ps-5 mb-2 pointer my-2" 
            [for]="'lastCheckOut-'+i">
              <div class="position-relative me-5 mt-2">
                <input class="form-input-checkbox" type="checkbox" 
                name="lastCheckOut"
                (click)="selectCheckOutChecked('lastCheckOut-'+i)"
                [checked]="currentlyCheckOutChecked() === 'lastCheckOut-'+i"
                formControlName="lastCheckOut" 
                [id]="'lastCheckOut-'+i">
                <div class="checkbox-check-container d-flex justify-content-center align-items-center">
                  <div class="icon icon-16 icon-checked-white"></div>
                </div>
              </div>
              <p class="d-flex flex-column my-2 ubuntu-regular font-size-16">
                {{'PUNCH_IN_OUT.CONDITIONS.WORKDEPARTMENT_CHECKOUT' | translate}}
              </p>
            </label>
            <div class="col-11 d-flex ubuntu-regular font-size-16 pe-0">
              <div class="col-3 d-flex align-items-center justify-content-around">
                <p class="me-2">{{'ABSENCE_REQUEST.RECAP_FROM' | translate}}</p>
                <ngb-timepicker formControlName="startHour" [spinners]="false" id="startTime"></ngb-timepicker>
              </div>
              <div class="col-3 d-flex align-items-center justify-content-around"
              [ngClass]="currentlyCheckOutChecked() === 'lastCheckOut-'+i ? 'disable-date' : ''">
                <p class="me-2">{{'ABSENCE_REQUEST.RECAP_TO' | translate}}</p>
                <ngb-timepicker
                formControlName="endHour" [spinners]="false" id="endTime">
              </ngb-timepicker>
              </div>
              <div class="col-6 d-flex align-items-center justify-content-around">
                <p class="me-2">{{'PUNCH_IN_OUT.CONDITIONS.FOR' | translate | lowercase}}</p>
                <ng-select formControlName="departmentCode" [items]="departments()" bindValue="code" bindLabel="label" placeholder="{{ 'PUNCH_IN_OUT.CONDITIONS.WORKDEPARTMENT_CHOOSE' | translate}}"
                  class="font-size-16 custom">
                </ng-select>
              </div>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center ps-0">
              <div (click)="deleteWorkOutsideDepartments(i)" class="icon icon-20 icon-delete-red-round pointer m-1"></div>
            </div>
            </div>
            <div class="row">
              <div class="col-1 d-flex offset-11 align-items-center justify-content-center ps-0">
                <div (click)="addWorkOutsideDepartments()" class="icon icon-20 icon-add-red-round pointer m-1"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>
  <div class="footer my-2 d-flex flex-column justify-content-center">
    <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18"
      (click)="save()">
      <ng-container *ngIf="punchInOutConditionsForm.controls.type.value === 'IN'">
        {{'PUNCH_IN_OUT.CONDITIONS.VALIDATE_IN' | translate}}
      </ng-container>
      <ng-container *ngIf="punchInOutConditionsForm.controls.type.value === 'OUT'">
        {{'PUNCH_IN_OUT.CONDITIONS.VALIDATE_OUT' | translate}}
      </ng-container>
    </button>
  </div>
</div>