import { Component, OnInit, Input, inject, DestroyRef, signal } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PunchInOutTypeEnum } from './../../../shared/enums/home/punchInOutType.enum';
import { HomeService } from 'src/app/features/home/home.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { ISavedPunchInOuts } from './punchInOut.interface';
import { SelectableItem } from '@shared/interfaces/selectableItem';
import { PunchInOutConditions } from '@shared/models/home/punchInOutConditions';

@Component({
  selector: 'app-punch-in-out-conditions',
  templateUrl: './punchInOutConditions.component.html',
  styleUrls: ['./punchInOutConditions.component.scss']
})
export class PunchInOutConditionsComponent implements OnInit {

  private readonly destroyRef = inject(DestroyRef);
  private readonly homeService = inject(HomeService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly snackbarService = inject(SnackbarService);
  protected readonly activeModal = inject(NgbActiveModal);

  @Input() date: string;

  protected readonly punchInOutTypeEnum = PunchInOutTypeEnum;
  protected readonly departments = signal<SelectableItem[]>([]);
  protected readonly currentlyCheckOutChecked = signal('');
  protected savedPunchInOuts$: Observable<ISavedPunchInOuts[]>;
  protected punchInOutConditionsForm: FormGroup;
  protected workOutsideDepartments: FormArray;

  ngOnInit() {
    this.getSavedPunchInOuts();
    this.setDepartments();
    this.initPunchInOutConditionsForm();
  }

  protected selectCheckOutChecked(checkbox: string) {
    const checkBoxIndex = Number(checkbox.charAt(checkbox.length - 1));
    const selectedEndHour = (this.punchInOutConditionsForm.get('workOutsideDepartments') as FormArray).at(checkBoxIndex).get('endHour');
    if (this.currentlyCheckOutChecked() === checkbox) {
      this.currentlyCheckOutChecked.set('');
      selectedEndHour.reset();
      selectedEndHour.enable();
      return;
    }
    this.currentlyCheckOutChecked.set(checkbox);
    selectedEndHour.reset();
    selectedEndHour.setValue({
      hour: new Date().getHours(),
      minute: new Date().getMinutes()
    });
    selectedEndHour.disable();
  }

  protected addWorkOutsideDepartments() {
    this.workOutsideDepartments = this.punchInOutConditionsForm.get('workOutsideDepartments') as FormArray;
    this.workOutsideDepartments.push(this.createWorkOutsideDepartmentsControl());
  }

  protected deleteWorkOutsideDepartments(index: number) {
    const form = this.punchInOutConditionsForm.get('workOutsideDepartments') as FormArray;
    form.length > 1 ? form.removeAt(index) : this.punchInOutConditionsForm.controls.workOutsideDepartments.reset();
    this.punchInOutConditionsForm.get('workOutsideDepartments').updateValueAndValidity();
  }

  protected save() {
    let action$: Observable<PunchInOutConditions | PunchInOutConditions[]>;
    if (this.punchInOutConditionsForm.controls.type.value === this.punchInOutTypeEnum.IN) {
      action$ = this.homeService.validatePunchInConditions(this.date)
        .pipe(
          tap(() => {
            this.snackbarService.success('PUNCH_IN_OUT.CONDITIONS.PUNCH_IN_SUCCESS');
            this.activeModal.close();
          })
        );

    }
    else {
      action$ = this.homeService.validatePunchOutConditions(this.date, this.punchInOutConditionsForm.controls.workOutsideDepartments.value)
        .pipe(
          tap(() => {
            this.snackbarService.success('PUNCH_IN_OUT.CONDITIONS.PUNCH_OUT_SUCCESS');
            this.activeModal.close();
          })
        );
    }

    action$.pipe(
      catchError(() => {
        this.snackbarService.error('PUNCH_IN_OUT.CONDITIONS.PUNCH_IN_OUT_ERROR');
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private getSavedPunchInOuts() {
    this.savedPunchInOuts$ = this.homeService.getPunchInOuts(this.date);
  }

  private initPunchInOutConditionsForm() {
    this.punchInOutConditionsForm = this.formBuilder.group({
      type: [this.punchInOutTypeEnum.IN],
      workOutsideDepartments: this.formBuilder.array([
        this.createWorkOutsideDepartmentsControl()
      ])
    });
  }

  private createWorkOutsideDepartmentsControl() {
    return this.formBuilder.group({
      startHour: null,
      endHour: { value: null, disabled: false },
      departmentCode: null,
      lastCheckOut: false,
    });
  }

  private setDepartments() {
    this.homeService.getDepartments()
      .pipe(
        tap(departments => this.departments.set(departments)),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }
}
