import { Component, OnInit, inject, signal, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, from, Observable, of, switchMap, tap } from 'rxjs';
import { ResetPasswordService } from './reset-password.service';
import { PasswordResetResponse, ResetPassword, ResetPasswordGroup } from './reset-password.model';
import { MetadataService } from '@shared/services/metadata.service';
import { UserService } from '@shared/services/user.service';
import { MetaData, MetaTableRequest } from '@shared/interfaces/metadata.model';
import {
  ProviderParameters,
  ProviderSettingModel,
  SmsBoxProviderSettings,
  SmsMessage,
  SmsType, TwilioProviderSettings, WhispirProviderSettings
} from '@shared/models/providers/provider.model';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { TranslateService } from '@shared/services/translate.service';
import { PROVIDER_LOGIC_NAME } from '@shared/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { HCAPTCHA_SECRET_KEY, HCAPTCHA_SITE_KEY } from '../../../environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { API_URLS } from '../../../environments/api-urls';
declare const hcaptcha: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  private readonly userService = inject(UserService);
  private readonly snackbarService = inject(SnackbarService);
  private readonly translateService = inject(TranslateService);
  private readonly metadataService = inject(MetadataService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly resetPasswordService = inject(ResetPasswordService);
  private destroyRef = inject(DestroyRef);
  private cdr = inject(ChangeDetectorRef);

  protected databases$ = this.resetPasswordService.getDataBases();
  protected resetFormGroup: FormGroup<ResetPasswordGroup>;
  protected sent = signal<boolean>(false);
  protected isProcessing = signal<boolean>(false);

  resetPassword: ResetPassword;
  captchaError = signal<string | null>(null);
  private smsPrefix: string;
  private siteName: string;
  private widgetId: any;

  ngOnInit(): void {
    this.resetFormGroup = this.formBuilder.group<ResetPasswordGroup>({
      siteId: new FormControl(null, [Validators.required]),
      adminNumber: new FormControl('', [Validators.required]),
      hcaptchaToken: new FormControl('', [Validators.required]),
      hcaptchaSiteKey: new FormControl(HCAPTCHA_SITE_KEY, [Validators.required]),
      phoneNumber: new FormControl(null, [Validators.max(9999), Validators.min(0), Validators.maxLength(4), Validators.minLength(4)]),
    });
    this.resetPassword = this.resetFormGroup.value as ResetPassword;

    this.resetFormGroup.get('siteId').valueChanges.pipe(
      switchMap(siteId => this.databases$.pipe(
        map(databases => databases.find(db => db.code === siteId))
      ))
    ).subscribe(selectedSite => {
      this.siteName = selectedSite ? selectedSite.label : '';
    });

    this.loadHCaptchaScript().then(() => {
      this.initCaptcha();
    }).catch(() => {
      this.handleError();
    });
    from(this.loadHCaptchaScript())
      .pipe(
        tap(() => this.initCaptcha()),
        catchError(() => {
          this.handleError();
          return of(EMPTY);
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private initCaptcha(): void {
    this.widgetId = hcaptcha.render('hcaptcha-container', {
      sitekey: HCAPTCHA_SITE_KEY,
      size: 'normal',
      callback: (token: string) => this.handleCaptchaSuccess(token),
      'expired-callback': () => this.handleCaptchaExpired(),
      'error-callback': () => this.handleCaptchaError()
    });
  }

  private loadHCaptchaScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = API_URLS.HCAPTACHA_URL;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
      document.head.appendChild(script);
    });
  }

  protected onSendPassword(): void {
    this.isProcessing.set(true);
    this.resetPassword = this.resetFormGroup.value as ResetPassword;
    this.handleHCaptchaSuccess();
  }

  private handleHCaptchaSuccess(): Promise<void> {
    return Promise.resolve(this.verifyTokenAndAccount(this.resetPassword).toPromise())
      .then((passwordResetResponse) => this.handleTokenVerificationSuccess(passwordResetResponse))
      .catch(() => this.handleError());
  }

  private handleTokenVerificationSuccess(passwordResetResponse: PasswordResetResponse): Promise<void> {
    if (!passwordResetResponse || passwordResetResponse?.newPassword === null) {
      return this.handleError();
    }
    return Promise.resolve(this.translateService.getTranslationInLanguage('RESET_PASSWORD.SMS_PREFIX', passwordResetResponse.language).toPromise())
      .then((translation) => ({
        passwordResetResponse,
        translation
      }))
      .then(({passwordResetResponse, translation}) => this.handleTranslationSuccess(passwordResetResponse, translation))
      .catch(() => this.handleError());
  }

  private handleTranslationSuccess(passwordResetResponse: any, translation: string): Promise<void> {
    this.smsPrefix = translation;

    const metaTableRequest: MetaTableRequest = {
      id: this.resetPassword.siteId,
      logicName: PROVIDER_LOGIC_NAME
    };

    return Promise.resolve(this.getMetaDataBy(metaTableRequest).toPromise())
      .then((metaDataList) => this.handleMetaDataSuccess(metaDataList, passwordResetResponse))
      .catch(() => this.handleError());
  }

  private handleMetaDataSuccess(metaDataList: any[], passwordResetResponse: any): Promise<void> {
    const activeMetaData = metaDataList.find(item => item.active);

    if (!activeMetaData) {
      return this.handleError();
    }

    return Promise.resolve(this.getProvidersSettings(this.resetPassword.siteId).toPromise())
      .then((listProvidersSettings) => this.handleProvidersSettingsSuccess(listProvidersSettings, activeMetaData, passwordResetResponse))
      .catch(() => this.handleError());
  }

  private handleProvidersSettingsSuccess(listProvidersSettings: any[], activeMetaData: any, passwordResetResponse: any): Promise<void> {
    const smsMessageRequest = this.generateSmsMessage(activeMetaData, listProvidersSettings, passwordResetResponse);
    return Promise.resolve(this.sendSmsPassword(smsMessageRequest).toPromise())
      .then((smsResponse) => this.handleSmsResponse(smsResponse))
      .catch(() => this.handleError());
  }

  private handleSmsResponse(smsResponse: string): void {
    if (smsResponse?.includes('ERROR')) {
      this.snackbarService.show(`RESET_PASSWORD.ERROR_SENDED`, 'error');
    } else {
      this.snackbarService.show(`RESET_PASSWORD.SUCCESS_SENDED`, 'success');
    }
    this.completeProcess();
  }

  private handleError(): Promise<void> {
    this.snackbarService.show(`RESET_PASSWORD.GENERIC_ERROR`, 'error');
    this.completeProcess(false);
    return Promise.resolve();
  }

  private completeProcess(sentSuccess = true): void {
    this.sent.set(sentSuccess);
    this.isProcessing.set(false);
  }

  verifyTokenAndAccount(resetPassword: ResetPassword): Observable<PasswordResetResponse> {
    return this.userService.verifyTokenAndAccount(resetPassword);
  }

  getMetaDataBy(metaTableRequest: MetaTableRequest): Observable<MetaData[]> {
    return this.metadataService.getMetaDataBy(metaTableRequest);
  }

  getProvidersSettings(siteId: string): Observable<ProviderSettingModel[]> {
    return this.metadataService.getProviderSettings(siteId);
  }

  sendSmsPassword(smsMessage: SmsMessage): Observable<string> {
    return this.metadataService.smsPasswordSms(smsMessage);
  }

  generateSmsMessage(activeProvider: MetaData,
                     listProvidersSettings: ProviderSettingModel[],
                     passwordResetResponse: PasswordResetResponse): SmsMessage {

    const type = SmsType[activeProvider.label as keyof typeof SmsType];
    const providerSettingsArray = listProvidersSettings.filter(setting => setting.code === activeProvider.code);
    const providerSettings: Record<ProviderParameters, string> = {} as Record<ProviderParameters, string>;

    switch (type) {
      case SmsType.SMSBOX:
        providerSettingsArray.forEach(setting => {
          providerSettings[setting.key as keyof SmsBoxProviderSettings] = setting.value;
        });
        break;

      case SmsType.TWILIO:
        providerSettingsArray.forEach(setting => {
          providerSettings[setting.key as keyof TwilioProviderSettings] = setting.value;
        });
        break;

      case SmsType.WHISPIR:
        providerSettingsArray.forEach(setting => {
          providerSettings[setting.key as keyof WhispirProviderSettings] = setting.value;
        });
        break;

      default:
        this.sent.set(true);
        this.isProcessing.set(false);
        this.snackbarService.show(`RESET_PASSWORD.GENERIC_ERROR`, 'error');
        throw new Error('Unsupported provider type');
    }

    const message = `${this.smsPrefix}: ${passwordResetResponse.newPassword}`;

    return {
      type,
      message,
      toPhoneNumber: passwordResetResponse.phoneNumber,
      siteName: this.siteName,
      siteKey: this.resetFormGroup.get('siteId').value,
      adminNumber: this.resetPassword.adminNumber,
      providerSettings
    };
  }

  handleCaptchaSuccess(token: string): void {
    this.resetFormGroup.patchValue({
      hcaptchaToken: token,
      hcaptchaSiteKey: HCAPTCHA_SECRET_KEY
    });
    this.cdr.detectChanges();
    this.captchaError.set(null);
  }

  handleCaptchaExpired(): void {
    this.resetPassword.hcaptchaToken = null;
  }

  handleCaptchaError(): void {
    this.resetPassword.hcaptchaToken = null;
    this.captchaError.set('Captcha failed. Please try again.');
  }
}
